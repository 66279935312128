.graphs-container {
}
.small-graph {
  float: left;
  width: 50%;
}

.clear {
  clear: both;
}

h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
}

h4 {
  margin-top: 50px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

p a {
  color: #3bbce6;
}

th,
td {
  padding: 10px;
  width: 25%;
}
tr {
  border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 600px) {
  .small-graph {
    float: unset;
    width: 100%;
  }
}

.analysis-group {
  background-color: '#fafafa';
  margin-top: 20;
  padding: 20;
}

.stark {
  background-color: black;
  color: white;
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

svg path:focus {
  outline: none;
}

input[type='text'],
textarea {
  font-size: 16px;
}
